<template>
    <div ref="el">
        <slot></slot>
    </div>
</template>

<script setup>
import {
    onMounted, inject, ref,
} from 'vue';
const props = defineProps({
    options: {
        type: Object,
        required: true,
    },
});
const el = ref(null);
const lax = inject('lax');
onMounted(() => {
    lax.addElement(el.value, props.options);
});
</script>
